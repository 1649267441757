import React from "react"
import { Stack, Main } from "@layout"
import PageTitle from "../components/Shared/PageTitle"
import Divider from "@components/Divider"
import Seo from "@widgets/Seo"
import AuthorExpanded from "../components/About/AuthorExpanded"
import { graphql, useStaticQuery } from "gatsby"
import "./animation.css"

const leadershipsQuery = graphql`
  query LeadershipsMarkdowns {
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { in: ["about", "about-person"] } }
      }
    ) {
      edges {
        node {
          frontmatter {
            avatar
            content_role
            description
            short_description
            name
            role
            contact {
              email
              phone_number
            }
            templateKey
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default function () {
  const data = useStaticQuery(leadershipsQuery)
  const leadershipData = data.allMarkdownRemark.edges.filter(
    edge => edge.node.frontmatter.templateKey === "about"
  )

  const leaderships = leadershipData.map((dataItem, idx) => {
    const node = dataItem.node
    const { slug } = node.fields
    const {
      avatar,
      content_role,
      description,
      name,
      role,
      short_description,
    } = node.frontmatter
    const { email, phone_number } = node.frontmatter.contact
    return {
      slug,
      avatar,
      content_role,
      description,
      short_description,
      name,
      role,
      contact: {
        email,
        phone_number,
      },
    }
  })

  const subheader = `We're a dedicated group of technology pros who develop mission-critical software solutions to enter, store and analyze data. And we've been consulting for Quick Base users since Quick Base was invented. Whether you need a new application, help integrating with QuickBooks or Excel, porting legacy applications to Quick Base, syncing with mobile devices or more, we can efficiently and affordably find the right solution for your business. We provide customized training and phone support.`

  return (
    <div className='lll'>
      <Seo title="Our Team" />
      <Divider space={3} />
      {/*
      <Stack effect="fadeInDown">
        <PageTitle header="Who We Are" subheader={subheader} />
      </Stack>
      <Stack>
        <Main style={{ marginBottom: "35px" }}>
          {leaderships.map((leadership, idx) => (
            <div id={`lead-${idx}`} key={`lead-${idx}`}>
              <Divider space={3} />
              <AuthorExpanded author={leadership} withLink />
            </div>
          ))}
        </Main>
          </Stack>*/}

        <div className="animateInDown">
          <PageTitle header="Who We Are" subheader={subheader} />
        </div>

        <div className='animateInUp'>
          <Main style={{ marginBottom: "35px", marginRight: 'unset' }}>
            {leaderships.map((leadership, idx) => (
              <div id={`lead-${idx}`} key={`lead-${idx}`} style={{padding: '20px'}}>
                <div >
                  <AuthorExpanded author={leadership} withLink />
                </div>
                
              </div>
            ))}
          </Main>
        </div>
    </div>
  )
}
